import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';


const HeadingText = styled.div`
    font-family: 'Merriweather', serif;
`;

const IndexPage = () => {
  return (
    <main>
      <Navigation />
      <h1>
        Dunning Currie Insurance Inc
      </h1>
      <HeadingText>DCI strives to provide the professionalism and expertise that is best in class in the industry, combined
          with local brokerage feel and &nbsp;accessibility.</HeadingText>
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
