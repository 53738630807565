import * as React from "react"
import styled from 'styled-components';
import heroImg from '../images/white_logo-03.png';

const NavigationContainer = styled.div`
max-height: 200px;
background: rgb(17, 37, 68);
color: white;
font-family: Poppins, sans-serif;
display: flex;
  padding: 20px;
justify-content: space-between;
box-shadow: rgb(0 0 0 / 1%) 0px 1.77104px 4.75968px, rgb(0 0 0 / 2%) 0px 4.25607px 11.4382px, rgb(0 0 0 / 2%) 0px 8.01379px 21.5371px, rgb(0 0 0 / 2%) 0px 14.2952px 38.4185px, rgb(0 0 0 / 3%) 0px 26.7377px 71.8575px, rgb(0 0 0 / 4%) 0px 64px 172px;
position: relative;
z-index: 100;

@media screen and (max-width: 850px) {
  padding: 10px;
display: block;

}
`;

const ImgContainer = styled.div`
display: inline-flex;
  max-width: 100%;


@media screen and (max-width: 850px) {
 
display: block;

img {
  max-width: 200px;
}
}

`;

const Nav = styled.div`
display: inline-flex;
width: 100%;
justify-content: flex-end;
align-items: center;

@media screen and (max-width: 850px) {
  display: block;
}
`;

const NavList = styled.ol`
list-style: none;
padding: 0px;
`;

const NavListItem = styled.li`
display: inline-flex;
text-align: right;
margin: 10px;

a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;

  @media screen and (max-width: 850px) {
    padding: 5px 5px;
  }
}

@media screen and (max-width: 850px) {
  margin: 5px;
}
`;

const NavListItemButton = styled(NavListItem)`
border: 2px solid white;
border-radius: 6px;
padding: 5px 10px;
background: rgb(89, 129, 144);


    @media screen and (max-width: 850px) {
      position: absolute;
      top: 20px;
      right: 20px;
    }

`;

const Navigation = () => {
  return (
    <NavigationContainer>
        <ImgContainer>
        <a href="/"><img src={heroImg} alt="Dunning Currie Insurance Inc" /></a>
        </ImgContainer>
        <Nav>
        <NavList>
          <NavListItem>
            <a href="/about">About</a>
          </NavListItem>
          <NavListItem>
            <a href="/our-team">Our Team</a>
          </NavListItem>
          <NavListItem>
            <a href="/products">Products</a>
          </NavListItem>
          <NavListItem>
            <a href="/claims">Claims</a>
          </NavListItem>
          <NavListItemButton>
            <a href="/contact">Contact</a>
          </NavListItemButton>
        </NavList>
        </Nav>
    </NavigationContainer>
  )
}

export default Navigation;